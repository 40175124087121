













































import NotifyUsersActionModel from "./NotifyUsersActionModel";
import { InputSetups } from "@/mixins/input-setups";

import { EntityTypes } from "piramis-base-components/src/components/SelectEntityWizard/includes/types";
import { MessageEditorWithMediaTab } from "piramis-base-components/src/components/NewMessageEditors/types";
import MultiMessageEditorWithMediaInput from "piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue";
import UsersInfoTable from "piramis-base-components/src/components/UsersInfoTable.vue";
import UsersInfoMixin from 'piramis-base-components/src/shared/logic/getUsersInfo/UsersInfoMixin'
import { FieldData } from "piramis-base-components/src/components/Pi/types";
import ChipsInput from "piramis-base-components/src/components/Pi/fields/ChipsInput/ChipsInput.vue";

import { Component, VModel, Mixins, Prop } from 'vue-property-decorator';
import { ValidationObserver } from "vee-validate";

@Component({
  data() {
    return {
      MessageEditorWithMediaTab,
      EntityTypes
    }
  },
  components: {
    ValidationObserver,
    UsersInfoTable,
    MultiMessageEditorWithMediaInput,
    ChipsInput
  }
})
export default class NotifyUsersActionView extends Mixins(InputSetups, UsersInfoMixin) {
  @VModel() model!: NotifyUsersActionModel

  @Prop() disabled!: boolean

  get boardKey() {
    return this.$store.state.boardsState.activeBoard!.board
  }

  userSetter(args: FieldData) {
    args.setter = (value) => this.userInfoModelSetter(args, { model: value }, this.model.users, this.model.usersInfoService)

    return args
  }

  format(tag: string | null) {
    return this.formatter(this.model.usersInfoService.usersInfos, tag)
  }
}
